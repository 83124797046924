<template>
    <section class="related-products p-t-20 p-b-40">
        <div class="container-fuild">
            <div class="heading text-center m-b-50">
                <div class="top">
                    <span v-if="lang === 'en'" class="uppercase">Similer</span>
                    <span v-if="lang === 'ru'" class="uppercase">Симилер</span>
                </div>

                <div class="bottom" v-if="lang === 'ar'">
                    <span class="uppercase">منتجات </span><span class="uppercase">ذات صله </span>
                </div>
                <div class="bottom" v-if="lang === 'en'">
                    <span class="uppercase">Rrlated </span><span class="uppercase">Products</span>
                </div>
                <div class="bottom" v-if="lang === 'ru'">
                    <span class="uppercase">Ррлат </span><span class="uppercase">Продукция</span>
                </div>
            </div>

            <div class="products">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6 m-b-40" v-for="productData in productsData.slice(2)" :key="productData.index">
                        <a :href="'/product/'+productData.slug">
                            <div class="single-watch">
                                <div class="watch-img">
                                    <img :src="productData.image" alt="Watch" class="img-fluid">
                                </div>

                                <div class="watch-data">
                                    <div class="name">
                                        <span class="uppercase">{{ productData.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/products/ar_products.json";
import enData from "../../data/products/en_products.json";
import ruData from "../../data/products/ru_products.json";
export default {
    name: "RelatedProducts",
    data() {
        return {
            lang: localStorage.lang,

            productsData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.productsData = arData;
        }
        if(localStorage.lang === "en") {
            this.productsData = enData;
        }
        if(localStorage.lang === "ru") {
            this.productsData = ruData;
        }
    }
}
</script>
