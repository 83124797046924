<template>
    <section class="gallery p-t-50 p-b-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-1.png" class="img-fluid">
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-2.png" class="img-fluid">
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-3.png" class="img-fluid">
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-4.png" class="img-fluid">
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-5.png" class="img-fluid">
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="mango-img">
                        <img src="@/assets/images/home/gallery/mango-6.png" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Gallery",
}
</script>
