<template>
    <div class="order">
        <Header />
        <OrderNow />
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layouts/Header.vue";
import OrderNow from '@/components/pages/order/OrderNow.vue';
import Footer from "@/components/layouts/Footer.vue";

export default {
    name: "Home",
    components: {
        Header,
        OrderNow,
        Footer
    },
};
</script>
