<template>
    <section class="mango p-t-50 p-b-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="mango-img">
                        <img src="@/assets/images/home/mango/mango-1.png">
                    </div>
                </div>

                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="content">
                        <div class="title">
                            <span>{{ mangoData.title_1 }}</span>
                        </div>

                        <div class="text">
                            <p v-for="paragraph in mangoData.mango_info" :key="paragraph.id">{{ paragraph }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row p-t-100">
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="mango-img">
                        <img src="@/assets/images/home/mango/mango-2.png">
                    </div>
                </div>

                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="content">
                        <div class="title">
                            <span>{{ mangoData.title_2 }}</span>
                        </div>

                        <div class="text">
                            <p v-for="paragraph in mangoData.mango_info_2" :key="paragraph.id">{{ paragraph }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/home/mango/ar_mango.json";
import enData from "../../data/home/mango/en_mango.json";
import ruData from "../../data/home/mango/ru_mango.json";
export default {
    name: "Mango",
    data() {
        return {
            mangoData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.mangoData = arData;
        }
        if(localStorage.lang === "en") {
            this.mangoData = enData;
        }
        if(localStorage.lang === "ru") {
            this.mangoData = ruData;
        }
    },
}
</script>
