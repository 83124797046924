<template>
    <div class="app-footer">
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="column m-b-30 col-lg-4 col-md-5 col-sm-8 col-12">
                        <div class="title">
                            <span class="title-text">{{ footerData.title_1 }}</span>
                        </div>
                        <div class="content">
                            <ul class="column-list">
                                <li class="m-b-10"><a href="tel:20643250690"><i class="fas fa-phone-alt"></i> <span>20643250690</span></a></li>
                                <li class="m-b-10"><a href="mailto:albesharycompany@gmail.com"><i class="far fa-envelope"></i> <span>albesharycompany@gmail.com</span></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="column m-b-30 col-lg-4 col-md-3 col-sm-4 col-12">
                        <div class="title">
                            <span class="title-text">{{ footerData.title_2 }}</span>
                        </div>
                        <div class="content">
                            <ul class="column-list">
                                <li class="m-b-10" v-for="page in footerData.pages" :key="page.id"><router-link :to="page.link"><span>{{ page.title }}</span></router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="column m-b-30 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div class="title">
                            <span class="title-text">{{ footerData.title_3 }}</span>
                        </div>
                        <div class="content">
                            <ul class="column-list">
                                <li class="m-b-20"><span class="lorim">{{ footerData.social_p }}</span></li>
                            </ul>

                            <div class="social-media">
                                <ul>
                                    <li><a href="https://www.facebook.com/albesharycompany" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://wa.me/+201280871043" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                    <li><a href="https://www.instagram.com/albesharycompany" target="_blank"><i class="fab fa-instagram" target="_blank"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UC4YwoImGaPVu3zZTNobZNMA" target="_blank"><i class="fab fa-youtube" target="_blank"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right text-center">
            <p>Copyright &copy; <span id="year"></span> Albeshary.com</p>
        </div>
    </div>
</template>

<script>
import arData from "../data/footer/ar_footer.json";
import enData from "../data/footer/en_footer.json";
import ruData from "../data/footer/ru_footer.json";
export default {
    name: "Footer",
    data() {
        return {
            footerData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.footerData = arData;
        }
        if(localStorage.lang === "en") {
            this.footerData = enData;
        }
        if(localStorage.lang === "ru") {
            this.footerData = ruData;
        }

        document.getElementById("year").innerHTML = new Date().getFullYear();
    }
}
</script>