<template>
    <div class="home">
        <Header />
        <Banner />
        <About />
        <Mango />
        <OurProducts />
        <Gallery />
        <Branches />
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layouts/Header.vue";
import Banner from "@/components/pages/home/Banner.vue";
import About from '@/components/pages/home/About.vue';
import Mango from '@/components/pages/home/Mango.vue';
import OurProducts from '@/components/pages/home/OurProducts.vue';
import Gallery from '@/components/pages/home/Gallery.vue';
import Branches from '@/components/pages/home/Branches.vue';
import Footer from "@/components/layouts/Footer.vue";

export default {
    name: "Home",
    components: {
        Header,
        Banner,
        About,
        Mango,
        OurProducts,
        Gallery,
        Branches,
        Footer
    },
};
</script>
