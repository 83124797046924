<template>
    <section class="about-us p-t-50 p-b-50">
        <div class="container">
            <div class="section-heading">
                <span>{{ aboutData.section_heading }}</span>
            </div>

            <div class="content">
                <div class="title">
                    <i class="far fa-building"></i> 
                    <span>{{ aboutData.title_1 }}</span>
                </div>

                <div class="text">
                    <p v-for="paragraph in aboutData.company_text" :key="paragraph.id">{{ paragraph }}</p>
                </div>

                <div class="title">
                    <i class="fas fa-history"></i>
                    <span>{{ aboutData.title_2 }}</span>
                </div>

                <div class="text">
                    <p v-for="paragraph in aboutData.company_history" :key="paragraph.id">{{ paragraph }}</p>
                </div>

                <div class="title">
                    <i class="fas fa-map-marked-alt"></i>
                    <span>{{ aboutData.title_3 }}</span>
                </div>

                <div class="text">
                    <p v-for="paragraph in aboutData.company_address" :key="paragraph.id">{{ paragraph }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/home/about/ar_about.json";
import enData from "../../data/home/about/en_about.json";
import ruData from "../../data/home/about/ru_about.json";
export default {
    name: "About",
    data() {
        return {
            aboutData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.aboutData = arData;
        }
        if(localStorage.lang === "en") {
            this.aboutData = enData;
        }
        if(localStorage.lang === "ru") {
            this.aboutData = ruData;
        }
    },
}
</script>
