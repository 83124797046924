<template>
    <section class="product-profile m-t-40 m-b-40">
        <div class="container-fluid">
            <div class="row" v-for="productData in singleProductData" :key="productData.index">
                <div class="col-lg-4 col-md-4 col-sm-12 col-12 m-b-20">
                    <div class="watch-images">
                        <div class="large-img">
                            <img :src="productData.image" class="img-fluid">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div class="watch-data">
                        <div class="watch-name">
                            <span class="uppercase">{{productData.name}}</span>
                        </div>

                        <div class="accordion" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <button class="accordion-button uppercase" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    <span v-if="lang === 'ar'">وصف المنتج</span>
                                    <span v-if="lang === 'en'">Description</span>
                                    <span v-if="lang === 'ru'">Описание</span>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                    <div class="accordion-body">
                                        <div class="watch-description">
                                            <p v-for="desc in productData.description" :key="desc.index">{{ desc }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                <button class="accordion-button uppercase" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                    <span v-if="lang === 'ar'">ملاحظة</span>
                                    <span v-if="lang === 'en'">Notic</span>
                                    <span v-if="lang === 'ru'">Нотик</span>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div class="accordion-body">
                                        <div class="watch-description">
                                            <p>{{ productData.notic }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/products/ar_products.json";
import enData from "../../data/products/en_products.json";
import ruData from "../../data/products/ru_products.json";
export default {
    name: "ProductProfile",
    data() {
        return {
            lang: localStorage.lang,

            productName: this.$route.params.productName,

            productsData: "",
            singleProductData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.productsData = JSON.stringify(arData);
        }
        if(localStorage.lang === "en") {
            this.productsData = JSON.stringify(enData);
        }
        if(localStorage.lang === "ru") {
            this.productsData = JSON.stringify(ruData);
        }

        this.singleProductData = JSON.parse(this.productsData).filter(product => product.slug === `${this.productName}`);
    }
}
</script>
