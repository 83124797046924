<template>
    <section class="branches p-t-50 p-b-50" id="branches">
        <div class="container">
            <div class="section-heading">
                <span>{{ branchesData.section_heading }}</span>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/egypt.png">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.egypt.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/jordan.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.jordan.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/emarat.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.uae.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/kuwait.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.kuwait.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/lubnan.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.lebanon.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/saudi.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.saudi.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/tunisia.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.tunisia.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="branch">
                        <div class="icon">
                            <img src="../../../assets/images/home/branches/russia.svg">
                        </div>

                        <div class="title">
                            <span>{{ branchesData.russia.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/home/branches/ar_branches.json";
import enData from "../../data/home/branches/en_branches.json";
import ruData from "../../data/home/branches/ru_branches.json";
export default {
    name: "Branches",
    data() {
        return {
            branchesData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.branchesData = arData;
        }
        if(localStorage.lang === "en") {
            this.branchesData = enData;
        }
        if(localStorage.lang === "ru") {
            this.branchesData = ruData;
        }
    },
}
</script>
