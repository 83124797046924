import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import Order from "../views/Order.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Albeshary - Home",
        },
    },
    {
        path: "/product/:productName",
        name: "Product",
        component: Product,
        meta: {
            title: "Albeshary - Product",
        },
    },
    {
        path: "/order",
        name: "Order",
        component: Order,
        meta: {
            title: "Albeshary - Order",
        },
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// Dynamic page title
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router;