<template>
    <div id="app">
        <transition @before-enter="openInTop" mode="out-in" appear>
            <router-view />
        </transition>

        <router-link class="shopping-now" to="/order">
            <i class="fas fa-shopping-cart"></i>
        </router-link>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    mounted() {
        if(!localStorage.lang || localStorage.lang === "") {
            localStorage.setItem("lang", "ar");
            this.$router.go(this.$router.currentRoute);
        }

        if(localStorage.lang === "ar") {
            $("#app").css("font-family", "'Cairo', sans-serif");
        } else {
            $("#app").css("font-family", "'Poppins', sans-serif");
        }
    },
    methods: {
        openInTop(){
            document.getElementById('app').scrollIntoView();
            $('body').css('overflow', 'auto');
        }
    }
}
</script>
