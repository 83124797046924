<template>
    <section class="banner">
        <div id="carouselExampleIndicators-slider-1" class="carousel slide d-lg-block d-md-block d-sm-none d-none" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators-slider-1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators-slider-1" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/images/home/banner/banner-1.jpg">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/images/home/banner/banner-2.jpg">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators-slider-1" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators-slider-1" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <div id="carouselExampleIndicators-slider-2" class="carousel slide d-lg-none d-md-none d-sm-block d-block" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators-slider-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators-slider-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators-slider-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/images/home/banner/dish-1.png">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/images/home/banner/dish-2.png">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/images/home/banner/dish-3.png">
                </div>
                <div class="caption text-center">
                    <p v-if="lang === 'ar'">
                        البشارى لتصدير الفواكه و الخضروات المجمدة كل منتجاتنا طازجة و مطابقة للمواصفات العالمة و معايير الجودة و الامان.
                    </p>
                    <p v-if="lang === 'en'">
                        Al-Bashari for the export of frozen fruits and vegetables all our products are fresh and conform to international specifications and standards of quality and safety.
                    </p>
                    <p v-if="lang === 'ru'">
                        Попкорн для экспорта замороженных фруктов и овощей вся наша продукция свежая.
                    </p>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators-slider-2" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators-slider-2" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: "Banner",
    data() {
        return {
            lang: localStorage.lang,
        }
    }
}
</script>
