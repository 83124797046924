<template>
    <div class="product">
        <Header />
        <ProductProfile />
        <RelatedProducts />
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layouts/Header.vue";
import ProductProfile from '@/components/pages/Product/ProductProfile.vue';
import RelatedProducts from '@/components/pages/Product/RelatedProducts.vue';
import Footer from "@/components/layouts/Footer.vue";



export default {
    name: "Product",
    components: {
        Header,
        ProductProfile,
        RelatedProducts,
        Footer
    }
};
</script>
