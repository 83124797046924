<template>
    <section class="our-products p-t-50 p-b-50" id="our-products">
        <div class="container">
            <div class="section-heading">
                <span>{{ ourProductsData.section_heading }}</span>
            </div>

            <div class="row justify-content-center m-b-30">
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <router-link to="/product/mango">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/mango.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.mango }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                    <router-link to="/product/strawberry">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/frawla.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.frawla }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <router-link to="/product/green-peas">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/besla-khadra.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.besla }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <router-link to="/product/green-beans">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/fasolya-khadra.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.fasolya }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <router-link to="/product/mixed-vegetables">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/khodar-meshakel.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.khodar }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <router-link to="/product/green-mallow">
                        <div class="product">
                            <img src="@/assets/images/home/our-products/mlokhya-khadra.png" class="img-fluid">
                            <div class="name text-center">
                                <span>{{ ourProductsData.mlokhya }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/home/our-products/ar_our-products.json";
import enData from "../../data/home/our-products/en_our-products.json";
import ruData from "../../data/home/our-products/ru_our-products.json";
export default {
    name: "OurProducts",
    data() {
        return {
            ourProductsData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.ourProductsData = arData;
        }
        if(localStorage.lang === "en") {
            this.ourProductsData = enData;
        }
        if(localStorage.lang === "ru") {
            this.ourProductsData = ruData;
        }
    },
}
</script>
