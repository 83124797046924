<template>
    <div class="app-header">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="header-content-top col-10">
                    <div class="brand-logo">
                        <img src="@/assets/images/logo/logo.png" alt="Albeshary" class="logo-img">
                    </div>

                    <div class="lang">
                        <div class="dropdown">
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Languages
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="javascript:void(0)" @click="ar()">عربى</a></li>
                                <li><a class="dropdown-item" href="javascript:void(0)" @click="en()">English</a></li>
                                <li><a class="dropdown-item" href="javascript:void(0)" @click="ru()">Русский</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="header-content-bottom col-12">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <ul class="navigation">
                                <li><router-link to="/">{{ headerData.home }}</router-link></li><span class="separator">|</span>
                                <li><a href="/#our-products">{{ headerData.products }}</a></li><span class="separator">|</span>
                                <li><a href="/#branches">{{ headerData.branches }}</a></li><span class="separator">|</span>
                                <li><router-link to="/order">{{ headerData.order }}</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import arData from "../data/header/ar_header.json";
import enData from "../data/header/en_header.json";
import ruData from "../data/header/ru_header.json";
export default {
    name: "Header",
    data() {
        return {
            headerData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.headerData = arData;
            $("#app").css("direction", "rtl");
        }
        if(localStorage.lang === "en") {
            this.headerData = enData;
        }
        if(localStorage.lang === "ru") {
            this.headerData = ruData;
        }
    },
    methods: {
        ar() {
            localStorage.setItem("lang", "ar");
            this.$router.go(this.$router.currentRoute);
        },
        en() {
            localStorage.setItem("lang", "en");
            this.$router.go(this.$router.currentRoute);
        },
        ru() {
            localStorage.setItem("lang", "ru");
            this.$router.go(this.$router.currentRoute);
        }
    }
}
</script>