import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Font Awesome
import "@fortawesome/fontawesome-free/js/all";

// Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Jquery
import "jquery";
import "jquery/dist/jquery";

// Normalize
import "normalize.css";

// Sal.js
import "./scss/sal.css";

// Scss
import "./scss/app.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
