<template>
    <section class="order-now p-t-100 p-b-100">
        <div class="container">
            <div class="section-heading">
                <span>{{ orderData.section_heading }}</span>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 m-b-20">
                    <div class="contact">
                        <span>{{ orderData.text_out }}</span>
                        <div class="icon">
                            <i class="fab fa-whatsapp-square"></i>
                        </div>
                        <div class="number">
                            <span>201280050073</span>
                        </div>
                        <div class="call">
                            <a href="https://wa.me/+201280050073" target="_blank">
                                {{ orderData.button_text }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="contact">
                        <span>{{ orderData.text_in }}</span>
                        <div class="icon">
                            <i class="fab fa-whatsapp-square"></i>
                        </div>
                        <div class="number">
                            <span>201280871043</span>
                        </div>
                        <div class="call">
                            <a href="https://wa.me/+201280871043" target="_blank">
                                {{ orderData.button_text }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import arData from "../../data/order/ar_order.json";
import enData from "../../data/order/en_order.json";
import ruData from "../../data/order/ru_order.json";
export default {
    name: "OrderNow",
    data() {
        return {
            orderData: "",
        }
    },
    mounted() {
        if(localStorage.lang === "ar") {
            this.orderData = arData;
        }
        if(localStorage.lang === "en") {
            this.orderData = enData;
        }
        if(localStorage.lang === "ru") {
            this.orderData = ruData;
        }
    },
}
</script>
